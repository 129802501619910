import React from 'react'

import Container from '../components/Container'
import CurrencyTableAll from '../components/CurrencyTableAll'
import CurrencyTablePopular from '../components/CurrencyTablePopular'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import SEOPageTitle from '../components/SEOPageTitle'
import SectionInner from '../components/SectionInner'

const CurrenciesWeSupport = () => {
  const seoNode = {
    title: 'Discover The Currencies That We Support - Zoopay',
    description: 'Discover the full list of currencies available on the Zoopay website.',
  }

  return (
    <Layout>
      <SEOPageTitle title={seoNode.title} />
      <SEO postNode={seoNode} pagePath="currencies-we-support" customTitle customDescription />

      <Container as="section" enableMobileGutter={false}>
        <SectionInner>
          <CurrencyTablePopular />
          <CurrencyTableAll />
        </SectionInner>
      </Container>
    </Layout>
  )
}

export default CurrenciesWeSupport
