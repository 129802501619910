import React from 'react'

import {
  CurTable,
  CTHeaderPlaceholder,
  CurrencyFlag,
  CurTableRow,
  CTHeader,
  CurrencyCode,
  CurrencyName,
  Bullet,
  Red,
  Green,
  SendOnly,
  ReceiveOnly,
} from './styles'

import iconNo from '../../images/icon-no.svg'
import iconYes from '../../images/icon-yes.svg'

const CurrencyTable = ({ currencies }) => (
  <CurTable>
    <thead>
      <tr>
        <CTHeader textLeft="true" colSpan="2">
          Currency
        </CTHeader>
        <CTHeaderPlaceholder>&nbsp;</CTHeaderPlaceholder>
        <CTHeader>Send / Receive</CTHeader>
        <CTHeader>Forward</CTHeader>
      </tr>
    </thead>
    <tbody>
      {currencies.map(({ node: currency }) => (
        <CurTableRow key={currency.id}>
          <CurrencyFlag country={currency.code === 'eur' ? 'eu' : currency.country.iso}>
            <div />
          </CurrencyFlag>
          <CurrencyName>{currency.name}</CurrencyName>
          <CurrencyCode>{currency.code}</CurrencyCode>
          <td>
            <Bullet>
              <Red display={!currency.sendPayment && !currency.receivePayment ? 'inline-block' : 'none'} src={iconNo} />
              <Green
                display={currency.sendPayment && currency.receivePayment ? 'inline-block' : 'none'}
                src={iconYes}
              />
              <SendOnly display={currency.sendPayment && !currency.receivePayment ? 'inline-block' : 'none'}>
                Send
                <br />
                Only
              </SendOnly>
              <ReceiveOnly display={!currency.sendPayment && currency.receivePayment ? 'inline-block' : 'none'}>
                Receive
                <br />
                Only
              </ReceiveOnly>
            </Bullet>
          </td>
          <td>
            <Bullet>
              <Red display={!currency.forward ? 'inline-block' : 'none'} src={iconNo} />
              <Green display={currency.forward ? 'inline-block' : 'none'} src={iconYes} />
            </Bullet>
          </td>
        </CurTableRow>
      ))}
    </tbody>
  </CurTable>
)

export default CurrencyTable
