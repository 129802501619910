import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Table from '../CurrencyTable'
import { CurrencyTableContainer, CurrencyTitle } from '../CurrencyTable/styles'

const CurrencyTableAll = () => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulCurrency(filter: { popular: { ne: true } }, sort: { fields: [name] }) {
          edges {
            node {
              code
              country {
                iso
              }
              forward
              id
              name
              onlinePlatform
              receivePayment
              sendPayment
              slug
            }
          }
        }
      }
    `}
    render={data => (
      <CurrencyTableContainer>
        <CurrencyTitle marginTop>All other currencies<span>.</span></CurrencyTitle>
        <Table currencies={data.allContentfulCurrency.edges} />
      </CurrencyTableContainer>
    )}
  />
)

export default CurrencyTableAll
