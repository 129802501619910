import styled, { css } from 'styled-components'
import SectionTitle from '../SectionTitle'

const CurrencyTableContainer = styled.div``

const CTHeader = styled.th`
  color: ${props => props.theme.colors.navy};
  font-family: ${props => props.theme.type.fonts.base};
  font-size: ${props => props.theme.type.sizes.tiny};
  line-height: 1;
  padding: 0 5px 18px;
  text-align: left;
  vertical-align: bottom;

  :first-child {
    padding-left: 1rem;
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    font-size: ${props => props.theme.type.sizes.small};
    text-align: ${props => (props.textLeft ? 'left' : 'center')};

    :first-child {
      padding-left: 0;
    }
  }
`

const CurTable = styled.table`
  border-spacing: 0;
  width: 100%;
  border-collapse: collapse;
`

const CurTableRow = styled.tr`
  border-bottom: 1px solid ${props => props.theme.colors.greyDark};

  :nth-child(odd) {
    background-color: ${props => props.theme.colors.greyLight};
  }

  :first-child td {
    border-top: 1px solid ${props => props.theme.colors.greyDark};
  }
`

const CTHeaderPlaceholder = styled.th`
  display: none;

  @media (min-width: ${props => props.theme.responsive.small}) {
    display: table-cell;
  }
`

const CurrencyFlag = styled.td`
  width: 82px;

  div {
    background-image: url('/images/flags/${props => props.country}.svg');
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: 50%;
    content: '';
    display: inline-block;
    height: 30px;
    margin-left: 10px;
    vertical-align: middle;
    width: 30px;
  }

  @media (min-width: ${props => props.theme.responsive.small}) {
    width: 95px;

    div {
      height: 38px;
      width: 38px;
      margin-left: 18px;
    }
  }
`

const CurrencyCode = styled.td`
  color: ${props => props.theme.colors.darkBlue};
  font-size: ${props => props.theme.type.sizes.small};
  font-weight: ${props => props.theme.type.weights.bold};
  text-align: center;
  text-transform: uppercase;
  max-width: 35px;

  @media (min-width: ${props => props.theme.responsive.medium}) {
    font-weight: ${props => props.theme.type.weights.normal};
    width: 70px;
  }
`

const CurrencyName = styled.td`
  color: ${props => props.theme.colors.darkBlue};
  display: none;
  font-size: ${props => props.theme.type.sizes.small};
  text-align: left;

  @media (min-width: ${props => props.theme.responsive.small}) {
    display: table-cell;
  }

  @media (min-width: ${props => props.theme.responsive.medium}) {
    width: 230px;
  }
`

const Bullet = styled.div`
  text-align: center;
`

const Red = styled.img`
  display: ${props => props.display};
  height: 30px;
  margin: 23px 0;
  width: 30px;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: 38px;
    margin: 23px 0;
    width: 38px;
  }
`

const Green = styled.img`
  display: ${props => props.display};
  height: 30px;
  margin: 23px 0;
  width: 30px;

  @media (min-width: ${props => props.theme.responsive.small}) {
    height: 38px;
    margin: 23px 0;
    width: 38px;
  }
`

const SendOnly = styled.div`
  color: ${props => props.theme.colors.navy};
  display: ${props => props.display};
  font-family: ${props => props.theme.type.fonts.base};
  font-size: ${props => props.theme.type.sizes.tiny};

  @media (min-width: ${props => props.theme.responsive.small}) {
    font-size: ${props => props.theme.type.sizes.xSmall};
  }
`

const ReceiveOnly = styled.div`
  color: ${props => props.theme.colors.navy};
  display: ${props => props.display};
  font-family: ${props => props.theme.type.fonts.base};
  font-size: ${props => props.theme.type.sizes.tiny};

  @media (min-width: ${props => props.theme.responsive.small}) {
    font-size: ${props => props.theme.type.sizes.xSmall};
  }
`

const CurrencyTitle = styled(SectionTitle)`
  @media (max-width: ${props => props.theme.responsive.medium}) {
    margin-left: 1rem;
    font-size: ${props => props.theme.type.sizes.medium};
  }

  ${props =>
    props.marginTop &&
    css`
      margin-top: 3.25rem;
    `}
`

export {
  Bullet,
  CTHeader,
  CTHeaderPlaceholder,
  CurTable,
  CurTableRow,
  CurrencyCode,
  CurrencyFlag,
  CurrencyName,
  CurrencyTableContainer,
  Green,
  ReceiveOnly,
  Red,
  SendOnly,
  CurrencyTitle,
}
